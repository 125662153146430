<template>
    <div class="authen_designer">
        <img class="logo" :src="bgImg" />
        <template v-if="state == -1">
            <div class="title">{{L['补充以下信息完成认证']}}</div>
            <div class="list none flex_row_start_start">
                <div class="left">{{L['供应商']}}:</div>
                <div class="group">
                    <div v-for="(item, index) in storeList.data" :key="index" @click="selectStore(index)"
                        :class="{ active: storeIndex == index }">{{ item.storeName }}</div>
                </div>
                <div v-if="errorInfo.storeName" class="error_info group">{{ errorInfo.storeName }}</div>
            </div>
            <div class="list flex_row_start_center">
                <div class="left">{{L['公司名称']}}:</div>
                <div class="input">
                    <input v-model="company" @input="getCompanyList" :placeholder="L['请输入公司名称']" maxlength="50" />
                </div>
                <div v-if="showCompanyList" class="companyList">
                    <el-scrollbar
                        :style="'height:' + (companyList.data.length > 5 ? '200px' : companyList.data.length * 40 + 'px')">
                        <div v-for="item in companyList.data" :key="item.enterpriseId" class="companyList_item"
                            @click="selectCompany(item)">{{ item.companyName }}-{{ item.enterpriseName }}</div>
                    </el-scrollbar>
                </div>
                <div v-if="errorInfo.company" class="error_info">{{ errorInfo.company }}</div>
            </div>
            <div class="list flex_row_start_center">
                <div class="left">{{L['设计师姓名']}}:</div>
                <div class="input">
                    <input v-model="name" @input="inputDesigner" :placeholder="L['请输入设计师姓名']" maxlength="20" />
                </div>
                <div v-if="errorInfo.name" class="error_info">{{ errorInfo.name }}</div>
            </div>
            <div class="btn" @click="submit">{{L['立即认证']}}</div>
        </template>
        <template v-else>
            <div class="result">
                <img class="imgs" v-if="state == 0 || state == 1" src="@/assets/designer_wait.png" />
                <img class="imgs" v-else-if="state == 2" src="@/assets/designer_pass.png" />
                <img class="imgs" v-else-if="state == 3" src="@/assets/designer_refused.png" />
                <div class="title" v-if="state == 0 || state == 1">{{L['认证审核中...']}}</div>
                <div class="title" v-else-if="state == 2">{{L['已认证为设计师用户！']}}</div>
                <div class="title" v-else-if="state == 3">{{L['审核未通过']}}</div>
                <div class="reason" v-if="state == 3">{{ reason? reason: '--' }}</div>
                <div class="btns" v-if="state == 3" @click="reApply">{{L['重新申请']}}</div>
            </div>
        </template>
    </div>
</template>

<script>
import { ref, getCurrentInstance, reactive, computed, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { ElMessage } from 'element-plus';

export default {
    setup(props) {
        const { proxy } = getCurrentInstance();
        const L = proxy.$getCurLanguage()
        const route = useRoute();
        const router = useRouter();
        const store = useStore();
        const state = ref(-2); //申请状态：-1-未申请；0-待分配商务；1-待商务审核；2-审核通过；3-审核失败
        const bgImg = require('@/assets/designer_apply.png');
        const storeIndex = ref(-1);
        const company = ref('');
        const enterpriseId = ref('');
        const name = ref('');
        const storeList = reactive({ data: [] });
        const showCompanyList = ref(false);
        const companyList = reactive({ data: [] });
        const isClick = ref(false);
        const reason = ref('');
        const errorInfo = reactive({
            storeName: '',
            company: '',
            name: '',
        })

        onMounted(() => {
            getInfo();
        })

        //获取认证状态
        const getInfo = () => {
            proxy.$get('v3/member/front/designerApply/state').then(res => {
                if (res.state == 200) {
                    state.value = res.data.state;
                    reason.value = res.data.refuseReason;
                    if (res.data.state == -1) {
                        getStoreList();
                    }
                }
            })
        };

        const getStoreList = () => {
            proxy.$get('v3/seller/front/store/storeList', { type: 1 }).then(res => {
                if (res.state == 200) {
                    storeList.data = res.data
                }
            })
        };

        const selectStore = (index) => {
            storeIndex.value = index;
            errorInfo.storeName = '';
        };

        const getCompanyList = () => {
            enterpriseId.value = '';
            errorInfo.company = '';
            if (!company.value.trim()) {
                company.value = '';
                showCompanyList.value = false;
                companyList.data = [];
                return;
            }
            proxy.$get('v3/member/front/enterprise/list', {
                enterpriseName: company.value
            }).then(res => {
                if (res.state == 200) {
                    companyList.data = res.data
                    if (res.data.length) {
                        showCompanyList.value = true;
                    } else {
                        showCompanyList.value = false;
                    }
                }
            })
        };

        const selectCompany = (item) => {
            company.value = item.companyName + '-' + item.enterpriseName;
            enterpriseId.value = item.enterpriseId;
            showCompanyList.value = false;
            companyList.data = [];
        };

        const submit = () => {
            let flag = true;
            if (storeIndex.value == -1) {
                // ElMessage.warning('请选择供应商');
                errorInfo.storeName = L['请选择供应商'];
                flag = false;
            }
            let companyName = company.value.split('-')[0]; //公司名称
            let enterpriseName = company.value.split('-')[1]; //部门名称
            if (!companyName.trim()) {
                // ElMessage.warning('请输入公司名称');
                errorInfo.company = L['请输入公司名称'];
                flag = false;
            } else if (!(enterpriseName && enterpriseName.trim())) {
                // ElMessage.warning('请选择正确的公司名称');
                errorInfo.company = L['请输入正确的公司名称'];
                flag = false;
            }
            if (!name.value.trim()) {
                // ElMessage.warning('请输入设计师姓名');
                errorInfo.name = L['请输入设计师姓名'];
                flag = false;
            }

            if (flag && !isClick.value) {
                isClick.value = true;
                proxy.$post('v3/member/front/designerApply/apply', {
                    designerName: name.value, //设计师名称
                    enterpriseName: company.value, //公司名称
                    // enterpriseId: enterpriseId.value, //企业id
                    storeId: storeList.data[storeIndex.value].storeId, //供应商id
                }).then(res => {
                    if (res.state == 200) {
                        state.value = 0;
                    } else {
                        ElMessage.warning(res.msg);
                        isClick.value = false;
                    }
                })
            }
        };

        const inputDesigner = () => {
            errorInfo.name = '';
        };

        const reApply = () => {
            state.value = -1;
            getStoreList();
        };

        return {
            state,
            bgImg,
            storeList,
            companyList,
            storeIndex,
            company,
            enterpriseId,
            name,
            showCompanyList,
            isClick,
            reason,
            errorInfo,
            getInfo,
            getStoreList,
            selectStore,
            getCompanyList,
            selectCompany,
            submit,
            reApply,
            inputDesigner,
            L
        };
    }
};
</script>

<style lang="scss" scoped>
.authen_designer {
    .logo {
        display: block;
        width: 1100px;
        margin: 0 auto;
    }

    .title {
        color: #333333;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        text-align: center;
        margin-top: 12px;
        margin-bottom: 50px;
    }

    .list {
        position: relative;
        width: 700px;
        margin: 0 auto 30px;

        &.none {
            margin-bottom: 15px;
        }

        .left {
            width: 80px;
            white-space: nowrap;
            flex-shrink: 0;
            color: #333333;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            text-align: right;
            margin-right: 20px;
        }

        .group {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-wrap: wrap;

            div {
                width: 180px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                color: #333333;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                background: #F7F7F7;
                border: 1px solid #F7F7F7;
                border-radius: 2px;
                margin-right: 20px;
                margin-bottom: 20px;
                cursor: pointer;

                &:hover,
                &.active {
                    color: #2877FA;
                    background: rgba(40, 120, 251, 0.08);
                    border-color: #2877FA;
                }
            }
        }

        .input {
            width: 580px;
            height: 40px;
            background: #F7F7F7;
            border-radius: 2px;

            input {
                width: 580px;
                height: 40px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                border: 1px solid #E5E5E5;
                padding-left: 10px;
                padding-right: 10px;
            }
        }

        .companyList {
            position: absolute;
            top: 40px;
            left: 100px;
            width: 580px;
            background: #FFFFFF;
            box-shadow: 0px 0px 10px 0px rgba(122, 122, 123, 0.15);
            border-radius: 2px;
            z-index: 999;

            .companyList_item {
                width: 580px;
                height: 40px;
                line-height: 40px;
                padding-left: 10px;
                padding-right: 10px;

                &:hover {
                    background: #2878fa2d;
                }
            }
        }

        .error_info {
            position: absolute;
            bottom: -22px;
            left: 102px;
            color: $colorMain2;

            &.group {
                bottom: -5px;
            }
        }
    }

    .btn {
        width: 240px;
        height: 48px;
        line-height: 48px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #FFFFFF;
        text-align: center;
        background: linear-gradient(180deg, #6A61EE 69%, #578BF5 100%);
        border-radius: 6px;
        margin: 70px auto 60px;
        cursor: pointer;
    }

    .result {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .imgs {
            width: 200px;
            height: 160px;
            margin-top: 40px;
        }

        .title {
            color: #333333;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            margin-top: 12px;
        }

        .reason {
            max-width: 749px;
            color: #888888;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            word-break: break-all;
            margin-top: 30px;
        }

        .btns {
            width: 250px;
            height: 48px;
            line-height: 48px;
            color: #FFFFFF;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            text-align: center;
            background: linear-gradient(180deg, #6A61EE 69%, #578BF5 100%);
            border-radius: 6px;
            margin-top: 78px;
            margin-bottom: 140px;
            cursor: pointer;
        }
    }
}
</style>